@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

footer {
  bottom: 0;
  margin-top: 20vh;
  width: 100%;
  height: 10vh;
  background-color: #242425;
}

footer p {
  color: white;
  text-align: center;
  justify-content: center;
  padding-top: 4vh;
}

@media (max-width: 858px) {
  footer p {
    color: white;
    padding-top: 4vh;
    text-align: center;
    justify-content: center;
    font-size: 10px;
  }
}
