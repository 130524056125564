@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

.left {
  text-align: left;
  margin: 0 0 0 120px;
}

.jkhubdmca {
  margin: 120px 0 20px 0;
}

@media (max-width: 800px) {
   .jkhubdmca {
      margin: 120px 0px 20px 0;
    }

    .left {
      text-align: left;
      margin: 0 0 0 10px;
      font-size: 13px;
    }
}
