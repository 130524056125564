/* SkeletonLoader.css */
.skeleton-wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(220px, 1fr)); /* Default for larger screens */
  gap: 60px; /* Gap between items */
  justify-content: center;
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.skeleton {
  height: 470px; /* Height of skeleton loader */
  width: 270px; /* Width of skeleton loader */
  background: #e0e0e0;
  background-image: linear-gradient(90deg, #e0e0e0, #ffffff, #e0e0e0);
  background-size: 200% 100%;
  animation: gradient-animation 1.5s ease infinite;
}

/* Media Queries */
@media (max-width: 1200px) {
  .skeleton-wrapper {
    gap: 30px; /* Gap adjustment for medium screens */
  }
}

@media (max-width: 768px) {
  .skeleton-wrapper {
    grid-template-columns: repeat(2, 1fr); /* 2 columns for tablets and small screens */
    gap: 10px; /* Gap adjustment for smaller screens */
    padding: 10px; /* Padding adjustment for smaller screens */
  }

  .skeleton {
    height: 290px;
    max-width: 170px; /* Height adjustment for tablets */
  }
}

@media (max-width: 480px) {
  .skeleton-wrapper {
    grid-template-columns: repeat(2, 1fr); /* 2 columns for mobile screens */
    gap: 10px; /* Gap adjustment for very small screens */
  }

  .skeleton {
    height: 500px; /* Height adjustment for very small screens */
    max-width: 170px; /* Height adjustment for tablets */
  }
}

@keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
