* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.movie-info-container {
  display: flex;
}

.main-card {
  text-align: center;
  margin: 25vh auto 0 11vw;
  padding: 10px;
  box-shadow: 1px 1px 10px rgba(6, 6, 6, 0.274);
  background-color: rgb(255, 255, 255);
}

/* Container for search bar and button */
.search-bar-container {
  box-shadow: 1px 1px 10px rgba(6, 6, 6, 0.274);
  background-color: rgb(255, 255, 255);
  margin: 25vh 11vw auto 2vw;
  padding: 10px;
  width: 25vw;
  height: 200vh;
}

/* Search bar inside the container */
.search-bar1 {
  position: relative;
  width: 100%; /* Full width of its parent container */
  max-width: 600px; /* Optional: limit the maximum width */
  margin: -55px 0 10px 0;
}

/* Adjust button styling to ensure it stays within the container */
.button-container {
  width: 100%; /* Ensure the button container uses full width */
  max-width: 600px; /* Optional: limit the maximum width */
  display: flex;
  justify-content: center; /* Center button horizontally */
  margin-top: 10px; /* Add space above button */
}

.heading-h {
  font-size: 25px;
  margin-top: 20px;
  font-weight: 500;
  margin-bottom: 20px;
}

.card-image {
  width: 70%;
  height: auto;
  margin: 0 auto;
  display: block;
}

.main-hr {
  border: 3px solid rgba(84, 84, 84, 0.785);
  margin: 20px 0;
}

.movie-h2 {
  font-size: 27px;
  font-weight: 500;
  letter-spacing: -0.8px;
  margin: 20px 0;
}

.rating {
  margin: 10px 0;
  font-size: 22px;
  color: rgb(52, 52, 52);
}

.rating b {
  font-weight: 500;
}

.rating span,
.descr span {
  color: red;
  font-weight: 400;
}

.genres,
.creator {
  margin: 10px 0;
  letter-spacing: -0.5px;
  font-size: 18px;
  color: rgba(0, 0, 0, 0.816);
}

.genres b,
.creator b {
  color: black;
  font-weight: 500;
}

.star {
  margin: 10px 0;
  letter-spacing: -0.5px;
  font-size: 18px;
  color: rgba(0, 0, 0, 0.816);
}

.star b {
  color: black;
  font-weight: 500;
}

.lan,
.quality {
  margin: 10px 0;
  letter-spacing: -0.5px;
  font-size: 18px;
  color: rgba(0, 0, 0, 0.816);
}

.lan b,
.quality b {
  color: black;
  font-weight: 500;
}

.screen {
  font-size: 30px;
  font-weight: 500;
  margin-bottom: 20px;
  color: red;
}

.grid-container {
  margin: 20px 0; 
  max-width: 100%;
}

.screenshots {
  width: 90%;
  height: auto;
  object-fit: cover;
}

.link {
  font-size: 27px;
  color: red;
  font-weight: 500;
  letter-spacing: -0.5px;
}

.notmain-hr {
  border: 1.5px solid rgba(44, 44, 44, 0.792);
  margin: 20px 0;
}

.links a {
  font-size: 22px;
  font-weight: 400;
  color: blue;
  text-decoration: none;
}

.single-link {
  color: red;
  font-size: 27px;
  font-weight: 500;
}

.single-link-item {
  font-size: 22px;
}

iframe {
  width: 100%;
  height: 450px;
  margin: 10px 0;
}

.descr {
  margin: 30px 0;
  text-align: justify;
}

@media (max-width: 1000px) {
  .search-bar-container {
    display: none;
  }

  .main-card {
    margin: 100px 0 0 0;
    width: 100%;
    background-color: #e9e9e9;
    box-shadow: none;
  }

  .heading-h {
    font-size: 20px;
  }

  .card-image {
    width: 90%;
  }

  .main-hr {
    margin: 10px 0;
  }

  .movie-h2 {
    font-size: 23px;
  }

  .rating {
    margin: 10px 0;
    font-size: 20px;
  }

  .star {
    margin: 10px 0;
    font-size: 16px;
  }

  .genres,
  .creator {
    font-size: 16px;
  }

  .lan,
  .quality {
    font-size: 16px;
  }

  .grid-container {
    margin: 20px 0;
  }

  .screenshots {
    width: 99%;
  }

  iframe {
    width: 100%;
    height: 240px;
  }

  .descr {
    margin: 10px 0;
  }
}

.loading {
  margin: 90% 40%;
  text-align: center;
  justify-content: center;
  align-items: center;
}
