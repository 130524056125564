@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Open Sans", sans-serif;
  list-style: none;
  text-decoration: none;
}

.navbar {
  position: fixed;
  top: 0;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 100px;
  background-color: #ffffff;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.45);
  color: #000000;
  width: 100%;
  height: 10vh;
  text-align: center;
}

.logo {
  width: 9vw;
  cursor: pointer;
}

.nav-links {
  display: flex;
  list-style-type: none;
  overflow: hidden;
}

.nav-links li {
  margin: 0 30px;
  color: #191919;
  text-transform: uppercase;
  font-size: 17px;
  font-weight: 500;
}

.nav-links li:hover {
  color: #000000;
  transition: all 0.3s ease-out;
  transform: scale(1.05);
}

.nav-btn {
  background: none;
  border: none;
  color: #000000;
  font-size: 1.5rem;
  cursor: pointer;
  display: none; /* Hide by default */
}

.nav-links.open {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 10vh;
  left: 0;
  width: 100%;
  height: 90vh;
  background-color: #ffffff;
  padding: 100px;
  transition: all 0.3s ease;
}

.nav-links.open li {
  margin-top: 25px;
  font-size: 19px;
  font-weight: 500;
}

@media (max-width: 1024px) {
  .navbar {
    padding: 0 50px;
  }

  .logo {
    width: 12vw;
  }

  .nav-links {
    display: none;
  }

  .nav-links li {
    margin: 0 20px;
    font-size: 16px;
  }

  .nav-btn {
    display: block;
    font-size: 24px;
  }
}

@media (max-width: 768px) {
  .navbar {
    padding: 0 20px;
  }

  .logo {
    width: 20vw;
  }

  .nav-links {
    display: none;
  }

  .nav-links.open {
    top: 10vh;
    height: calc(100vh - 10vh);
  }
}

@media (max-width: 480px) {
  .navbar {
    padding: 0 20px;
  }

  .logo {
    width: 30vw;
  }

  .nav-btn {
    font-size: 28px;
  }

  .nav-links.open {
    padding: 50px;
  }

  .nav-links li {
    font-size: 18px;
    margin-bottom: 10px;
  }
}
